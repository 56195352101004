<template>
	<v-container v-if="!this.$store.getters.is_authenticated">{{ $t("system.login_required") }}</v-container>
	<v-container fluid v-else>
		<v-container>
			<v-row dense v-if="is_cert_data_current()">
				<v-col cols="12" sm="12">
					<v-card class="pa-2" outlined tile elevation="3">
						<v-card-title>{{$t('education.title_overview')}}</v-card-title>
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr><td v-for="header in headers" :key="header" class="centered"><b>{{ header }}</b></td></tr>
								</thead>
								<tbody>
									<tr v-for="test in tests" :key="test.id">
										<td>{{ test.labelShort }}</td>
										<td v-if="test.statusTest == 'create'" class="centered">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn :disabled="is_active(test.statusStudent)" icon v-bind="attrs" v-on="on" route :to="'test/' + test.id"><v-icon>mdi-play-box</v-icon></v-btn>
												</template>
												<span>{{ $t('education.btn_run_test') }}</span>
											</v-tooltip>
										</td>
										<td v-else-if="test.statusTest == 'fail'" class="centered"><v-icon :title="$t('education.status_fail')">mdi-close-box</v-icon></td>
										<td v-else-if="test.statusTest == 'pass'" class="centered"><v-icon :title="$t('education.status_pass')">mdi-checkbox-marked</v-icon></td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card>
				</v-col>
			</v-row>
			<v-row dense justify="center" v-else>
					<v-card>
						<v-card-title class="text-h5">{{ $t("education.check") }}</v-card-title>
						<v-card-text>{{ $t("education.check_long") }}</v-card-text>
						<v-card-text>{{ $t("education.check_long_sub") }}</v-card-text>
						<v-card-text><v-btn route to="/edit/2">{{ $t("education.check_redir") }}</v-btn></v-card-text>
					</v-card>
			
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {
	data() {
		return {
			headers: [
				this.$i18n.t("education.header_label"),
				this.$i18n.t("education.header_status"),
			],
			tests: [],
			last_confirm: '0000-00-00'
		};
	},
	watch: {
		
	},
	methods: {
		is_active(statusStudent) {
			if(statusStudent == 'active') return false
			else return true
		}, 
		is_cert_data_current() {
			var cur_date = new Date()
			var y = cur_date.getFullYear()
			var m = String(cur_date.getMonth() + 1)
			if(m.length == 1) m = '0' + m
			var d = String(cur_date.getDate())
			if(d.length == 1) d = '0' + d
			var x = y + '-' + m + '-' + d
			if(this.last_confirm == x) return true;
			else return false
		}
		
	},
	mounted() {
		this.current_user_id = this.$store.getters.get_user_id

		ApiService.get(`/auth/info/` + this.current_user_id).then((response) => {
			this.last_confirm = response.data.certDataLastConfirm
		}).catch(() => {})
		ApiService.get(`/education/tests/` + this.current_user_id).then((response) => {
			this.tests = response.data;
		}).catch(() => {});
		
	},
};
</script>

<style scoped>
.centered {
	text-align: center;
}

</style>
